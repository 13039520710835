<template>
    <div>
        <ValidationObserver ref="detailFormValid">
            <b-row>
                <b-col cols="12">
                    <h6> {{ innerValue.name }} {{ innerValue.surname }} </h6>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox :disabled="disabled" :validate-error="errors[0]"
                                v-model="innerValue.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="cgpa" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('cgpa')">
                            <b-form-input type="text" :disabled="disabled" v-model="innerValue.cgpa">
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="cgpa" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('class')">
                            <classes-selectbox v-model="innerValue.class" :disabled="disabled"
                                :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('faculty_name')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.faculty_name) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('program_name')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.program_name) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('semester_count')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.semester_count) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('prep_status')">
                        <b-input-group>
                            <div class="label-as-input">{{ getLocaleText(innerValue.prep_status, 'name') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('registration_type')">
                        <b-input-group>
                            <div class="label-as-input">{{ getLocaleText(innerValue, 'registration_type_text') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('student_number')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.student_number) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4" v-if="innerValue.student_program.student.person.nationality_code != 'TR'">
                    <b-form-group :label="$t('equivalence_status')">
                        <b-input-group>
                            <div class="label-as-input">{{ innerValue.equivalence_status ? $t('yes') : $t('no') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('success_rate1')">
                        <b-input-group>
                            <div class="label-as-input">{{ innerValue.success_rate || "-" }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col cols="12">
                    <h6 class="mt-4 mb-2">{{ $t('documents') }}</h6>
                </b-col>
                <b-col cols="12">
                    <div class="d-flex flex-wrap">
                        <b-card :sub-title="getLocaleText(doc, 'name')" class="mr-2 mt-2" style="min-width: 18rem;"
                            v-for="(doc, index) in innerValue.documents" :key="index">

                            <b-card-text>{{ $t('status') }}: {{ $t(`status_${doc.status}`) }}</b-card-text>
                            <template #footer>
                                <div class="d-flex flex-row justify-content-around">
                                    <a class="card-link" @click="showDoc(doc)">{{ $t('show') }}</a>

                                </div>
                            </template>
                        </b-card>
                        <b-card :sub-title="$t(doc)" class="mr-2 mt-2" style="min-width: 18rem;"
                            v-for="(doc, index) in insideDocument" :key="index">
                            <template #footer>
                                <div class="d-flex flex-row justify-content-around">
                                    <a class="card-link" @click="downloadInsideDocument(doc)">{{ $t('show') }}</a>
                                </div>
                            </template>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="font-weight-medium mb-1">
            {{ $t("your_preferences").toUpper() }}
        </div>
        <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered
            :items="innerValue.double_major_preferences" :fields="doubleMajorPreferencesFields" show-empty
            class="mb-4 table-dropdown no-scrollbar border rounded">
            <template #cell(rank)="row">
                <div class="d-flex align-items-center justify-content-around rank-container">
                    {{ row.item.rank }}
                </div>

            </template>
            <template #cell(faculty_program)="row">
                {{ row.item.faculty_name }} <br />{{ row.item.program_name }}
            </template>
            <template #cell(result)="row">
                <template v-if="!row.item.result">-</template>
                <template v-else>
                    <span class="badge" :class="['placed', 'other_preference_placed'].includes(row.item.result) ? 'badge-success'
                        : row.item.result == 'not_placed' ? 'badge-danger' : 'badge-warning'">
                        {{ $t(`application_result.${row.item.result}`) }}
                    </span>
                </template>
            </template>
            <template #cell(status)="row">
                {{ row.item.status_text }}
            </template>
            <template #cell(explanation)="row">
                <div class="d-flex justify-content-center">
                    {{ row.item.explanation || "-" }}
                </div>
            </template>
        </b-table>
        <CommonModal ref="updateModal" size="md" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t("status_update").toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="updateModalValid">
                    <b-row>
                        <b-col cols="12">
                            <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('status')">
                                    <b-form-select :validate-error="errors[0]" v-model="update.status"
                                        :state="errors[0] ? false : null"
                                        :options="[{ text: $t('declined'), value: 'student_affairs_declined' }, { text: $t('status_approved'), value: 'approved' }]">
                                    </b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />

                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" v-show="update.status == 'student_affairs_declined'">
                            <ValidationProvider name="explanation"
                                :rules="`${update.status == 'student_affairs_declined' ? 'required' : ''}`"
                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('explanation')">
                                    <b-form-input :validate-error="errors[0]" v-model="update.explanation" type="text"
                                        :state="errors[0] ? false : null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-secondary" @click="$refs.updateModal.$refs.commonModal.hide()">
                                {{ $t("close") }}
                            </button>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-primary float-right" @click="updateStatus">
                                {{ $t("save") }}
                            </button>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </template>
        </CommonModal>
    </div>
</template>

<script>
import DoubleMajorApplicationService from "@/services/DoubleMajorApplicationService";
import StudentDocumentService from "@/services/StudentDocumentService";

import { EventBus } from '@/main'

import { downloadInsideDocument } from "../function"

export default {
    name: "MinorApplicationAdminForm",
    props: {
        minorApplication: {
            type: Object,
        },
        type: {
            type: String,
            default: "",
        },
        value: {
            type: Object,
            default: () => { },
        },
    },
    watch: {
        'innerValue.minor_application_preferences': {
            handler(val) {
                if (val?.length > 0) {
                    this.updatable = val.map(item => ({ status: false, id: item.id, rank: item.rank }))
                }
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        innerValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        rankOptions() {
            return this.innerValue.minor_application_preferences.map(item => ({ text: item.rank, value: item.rank }))
        },
        disabled() {
            return ["show", "delete"].includes(this.type)
        }
    },

    data() {
        return {
            downloadInsideDocument,
            note: {},
            formLoading: false,
            updateRank: { rank: null },
            oldRank: null,
            update: {
                status: "",
                explanation: "",
            },
            insideDocument: ['student_transcript',
                'student_english_language_proficiency_document'],
            documentLoading: false,
            updatable: [],
            minorApplicationId: null,
            doubleMajorPreferencesFields: [
                {
                    key: "rank",
                    label: this.$t("rank").toUpper(),
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                    sortable: false,
                },
                {
                    key: "faculty_program",
                    label: this.$t("faculty").toUpper(),
                    tdClass: "align-middle",
                    sortable: false,
                },
                {
                    key: "result",
                    label: this.$t("result").toUpper(),
                    tdClass: "align-middle",
                },
                {
                    key: "status",
                    label: this.$t("status").toUpper(),
                    tdClass: "align-middle",
                    sortable: false,
                },
                {
                    label: this.$t("explanation").toUpper(),
                    key: "explanation",
                },
            ],

            extraInfo: false,
        };
    },
    methods: {
        async showDoc(file) {
            const type = file.name == 'ÖSYM Sonuç Belgesi' ? 'osym_result_document' : file.name == 'ÖSYM Yerleştirme Belgesi' ? 'osym_placement_document' : ''
            const res = await DoubleMajorApplicationService.studentAffairsDownloadDocument(this.innerValue.id, type).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );

            EventBus.$emit('pdfViewerModalShow', { pdfFileData: res.data, pdfFileName: file.filename });
        },
        async updateStatus() {
            const valid = await this.$refs['updateModalValid'].validate()

            if (!valid) {
                return
            }

            if (this.update.status === 'approved') {
                delete this.update.explanation
            }
            const response = await DoubleMajorApplicationService.studentAffairsApprovePreference(this.update)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))


            if (response.data.success) {
                this.$refs.updateModal.$refs.commonModal.hide()

                this.$emit('getItem', this.innerValue.id)
                this.update = {}
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        downloadDocument(name) {
            this.documentLoading = true
            if (name === "student_transcript") {
                StudentDocumentService.downloadTranscript(this.innerValue.student_program_id, this.$i18n.locale)
                    .then(response => {
                        let fileName = this.innerValue.student_program_id + '-' + this.$t('transcript') + '-' + this.$i18n.locale + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                    }).finally(() => {
                        this.documentLoading = false
                    })
            } else if (name === "student_english_language_proficiency_document") {
                StudentDocumentService.downloadLanguageProficiency(this.innerValue.student_program_id, this.$i18n.locale)
                    .then(response => {
                        let fileName = this.innerValue.student_program_id + '-' + this.$t('file_name_language_proficiency') + '-' + this.$i18n.locale + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                    })
                    .catch(e => {
                        this.showErrors(e, null, true)
                    }).finally(() => {
                        this.documentLoading = false
                    })
            }

        },
    },
    created() {
        // this.minorApplicationId = this.innerValue.id;
        // this.minorApplicationPreferences = this.innerValue.minor_application_preferences
        // this.innerValue.minor_application_preferences.forEach((itm, index) => {
        //     this.minorApplicationPreferences[index].prep_score = this.innerValue.prep_score
        // })
    },
};
</script>

<style lang="scss">
.rank-container {
    i {



        width: 24px;
        height: 24px;

        &::before {
            width: 40px;
            height: 40px;

            width: 24px;
            height: 24px;
        }
    }
}
</style>